<template>
  <div id="container" v-if="fetch_data">
    <section id="shop">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>お届け先の<br class="sp">入力</p>
          </div>
          <div class="step_box flex on">
            <div class="num">2</div>
            <p>ご注文内容の<br class="sp">確認</p>
          </div>
          <div class="step_box flex on">
            <div class="num">3</div>
            <p>決済情報の<br class="sp">入力</p>
          </div>
          <div class="step_box flex on">
            <div class="num">4</div>
            <p>ご注文を<br class="sp">受け付けました</p>
          </div>
        </div>


        <div class="cts_box form">
          <h2 class="sub_ttl"><span>{{ fetch_data.shopping_count }}点の商品のご注文を受け付けました</span></h2>
          <p class="lead_txt">
            以下の商品のご注文を受け付けました。お買い上げいただきありがとうございました。<br>
            自動送信メールが配信されておりますので内容をご確認ください。<br>
            塾生の方はマイページの「オンラインショップ購入履歴」からご注文商品内容をご確認いただけます。<br>
          </p>
          <h3 class="min_ttl">ご注文内容</h3>
          <div class="cart_box">
            <div class="cart_item flex" v-for="article in fetch_data.shopping.articles"
                 v-bind:key="article.article.id">
              <div class="image"><img v-bind:src="article.article.image_files[0]" alt=""></div>
              <div class="cart_cts">
                <h3 class="item_ttl">{{ article.article.title }}</h3>
                <div class="item_count alnR">
                  <p>数量: {{ article.count }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="btnBox mt40" v-if="isUserSignedIn()">
            <router-link :to="{name: 'UsersCustomerShoppingHistories'}" class="submit basic arrow">オンラインショップ購入履歴
            </router-link>
          </div>
          <div class="btnBox mt40" v-else>
            <router-link to="/" class="submit basic">複眼経済塾トップ</router-link>
          </div>

        </div>

      </div>
    </section>

    <PaygentStatusModal v-if="paygent_status === 'status_waiting' || paygent_status === 'status_authory_ok'" />
    <PaygentStatusFailedModal v-if="paygent_status === 'status_failed'" />
    <PaygentStatusCancelModal v-if="paygent_status === 'status_cancel'" />

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import PaygentStatusModal from '@/components/paygentStatusModal'
import PaygentStatusFailedModal from '@/components/paygentStatusFailedModal'
import PaygentStatusCancelModal from '@/components/paygentStatusCancelModal.vue'

export default {
  mixins: [Libraries],
  components: {PaygentStatusModal, PaygentStatusFailedModal, PaygentStatusCancelModal},
  data() {
    return {
      fetch_data: null,
      paygent_status: 'status_paid'
    }
  },
  mounted() {
    // form token
    this.removeFormToken('Shopping')

    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}shop/shopping.json`, {
            params: {
              id: this.$route.params.id,
            }
          })
          .then(response => {
            this.fetch_data = response.data
            // sync paygent status
            this.startPaygentStatusPolling((status) => {
              this.paygent_status = status
            });
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }
}
</script>
